import Endpoints from '~/models/constants/Endpoints'
import { RestService } from '~/services/RestService'
import type { Provider } from '~/models/provider/Provider'

class ProviderService extends RestService {
  async createProvider(provider: Partial<Provider>) {
    const response = await this.postSecured(Endpoints.PROVIDERS, provider, {})
    return this.handleResponse(response)
  }

  async updateProvider(provider: Partial<Provider>) {
    const response = await this.put(`${Endpoints.PROVIDERS}`, provider, {})
    return this.handleResponse(response)
  }

  async getProviderByUserId(userId: number | undefined) {
    const response = await this.findSecured(
      `${Endpoints.PROVIDERS}/users/${userId}`,
      {}
    )
    return this.handleResponse(response)
  }

  async findById(id: string) {
    const response = await this.find(`${Endpoints.PROVIDERS}/${id}`, {})
    return this.handleResponse(response)
  }

  async findByIdForEdit(id: string) {
    const response = await this.findSecured(
      `${Endpoints.PROVIDERS}/${id}/edit`,
      {}
    )
    return this.handleResponse(response)
  }

  async getRelatedProviders(id: string | undefined) {
    const response = await this.find(
      `${Endpoints.PROVIDERS}/${id}/relateds`,
      {}
    )
    return this.handleResponse(response)
  }

  async updateProviderPhoneClicksCount(providerId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.patch(
        `${Endpoints.PROVIDERS}/${providerId}/phone-clicks`,
        {},
        {}
      )

    return this.handleResponse(response)
  }

  async approveProvider(providerId: string) {
    const response = await this.patch(
      `${Endpoints.PROVIDERS}/${providerId}/approve`,
      {},
      {}
    )

    return this.handleResponse(response)
  }

  async rejectProvider(providerId: string) {
    const response = await this.patch(
      `${Endpoints.PROVIDERS}/${providerId}/reject`,
      {},
      {}
    )

    return this.handleResponse(response)
  }

  async getPendingProviders() {
    const response = await this.findSecured(
      `${Endpoints.PROVIDERS}/pending`,
      {}
    )
    return this.handleResponse(response)
  }
}

export default new ProviderService()
